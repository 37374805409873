<template>
	<div class="loginpage">
		<div class="loginpage__box">
			<login-card
				type="page"
				:loginSuccessRedirectPath="loginSuccessRedirectPath"
				@changeState="changeState"
				@showErrorPopup="showErrorPopup"
				@close="goToDiscovery"
			/>
		</div>

		<popup-login-error
			v-if="isShowErrorPopup"
			:email="email"
			:title="$t('login.email_not_registered')"
			:description="$t('login.register_email')"
			:primaryButtonText="$t('register.change')"
			:secondaryButtonText="$t('register.register')"
			:secondaryButtonLink="registerLink"
			@close="closeErrorPopup"
			@primaryAction="closeErrorPopup"
		/>
	</div>
</template>

<script>
import loginCard from "~/components/cards/login/index.vue"
import PopupLoginError from "~/components/popup/login/error/index.vue"

import seo from "@/helpers/seoHelper"

export default {
	layout: "withFooter",
	middleware: ["notAuthenticated"],
	components: {
		loginCard,
		PopupLoginError
	},
	mixins: [seo],
	head() {
		return {
			title: this.$t("login.meta_title"),
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.$t("login.meta_desc")
				},
				{
					hid: "keywords",
					name: "keywords",
					content: this.$t("login.meta_keyword")
				},
				{
					hid: "og:title",
					property: "og:title",
					content: this.$t("login.meta_title")
				},
				{
					hid: "og:description",
					property: "og:description",
					content: this.$t("login.meta_desc")
				},
				{
					hid: "og:image",
					property: "og:image",
					content:
						"https://secure-images.bridestory.com/image/upload/v1458716392/banner/opengraph/tagline.png"
				},
				{
					hid: "twitter:title",
					name: "twitter:title",
					content: this.$t("login.meta_title")
				},
				{
					hid: "twitter:description",
					name: "twitter:description",
					content: this.$t("login.meta_desc")
				},
				{
					hid: "twitter:image",
					name: "twitter:image",
					content:
						"https://secure-images.bridestory.com/image/upload/v1458716392/banner/opengraph/tagline.png"
				}
			]
		}
	},
	data() {
		return {
			loginSuccessRedirectPath: "",
			isShowErrorPopup: false,
			state: 1,
			email: ""
		}
	},
	computed: {
		registerLink() {
			if (this.$nuxt.$route.query.redirect_uri && this.email) {
				return this.localeLink(
					`register?redirect_uri=${this.$nuxt.$route.query.redirect_uri}&email=${this.email}`
				)
			} else if (this.email) {
				return this.localeLink(`register?email=${this.email}`)
			} else {
				return this.localeLink(`register`)
			}
		}
	},
	methods: {
		goToDiscovery() {
			window.location.pathname = "/"
		},
		changeState(value) {
			this.state = value
		},
		showErrorPopup(email) {
			this.isShowErrorPopup = true
			this.email = email
		},
		closeErrorPopup() {
			this.isShowErrorPopup = false
			this.email = ""
		}
	},
	beforeMount() {
		this.pageView({
			v2_pageProperties: {
				category: "login",
				subCategory: undefined,
				properties: undefined
			}
		})
	},
	mounted() {
		this.$nextTick(() => {
			this.$nuxt.$loading.finish()
		}, 500)
	},
	async fetch({ store }) {
		try {
			// store.dispatch("setZendeskLoaded", true)
			store.dispatch("showHeader", true)
			store.dispatch("showFooter", true)
		} catch (err) {
			console.log(err)
		}
	}
}
</script>

<style lang="scss" src="./style.scss" />
