<template>
	<div>
		<template>
			<div class="modal-backdrop" @click="closePopup"></div>
			<div class="modal">
				<div class="login__error">
					<div class="login__error__content">
						<h3 class="login__error__title">{{ title }}</h3>
						<p class="login__error__description">
							{{ description }}
							<strong>{{ email }}</strong>
						</p>
						<div class="login__error__button-container">
							<a v-if="primaryButtonLink" class="login__error__button login__error__button--outline" :href="primaryButtonLink">
								{{ primaryButtonText }}
							</a>
							<button v-else class="login__error__button login__error__button--outline" @click="primaryButtonAction">
								{{ primaryButtonText }}
							</button>
							<a v-if="secondaryButtonLink" class="login__error__button login__error__button--fill" :href="secondaryButtonLink">
								{{ secondaryButtonText }}
							</a>
							<button v-else class="login__error__button login__error__button--fill" @click="secondaryButtonAction">
								{{ secondaryButtonText }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "PopupLoginRegisterError",
	props: [
		"title",
		"description",
		"primaryButtonText",
		"primaryButtonLink",
		"secondaryButtonText",
		"secondaryButtonLink",
		"email"
	],
	methods: {
		primaryButtonAction() {
			this.$emit('primaryAction')
		},
		secondaryButtonAction() {
			this.$emit('secondaryAction')
		},
		closePopup(){
			this.$emit("close")
		}
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />